import React, { CSSProperties, Fragment, memo } from 'react';
import cn from 'classnames';
import styles from './index.module.scss';
import { ICTAs } from '../../store';
import { useSpring, animated } from 'react-spring';
import {
  mediaAnimationProps,
  mapCTAsAlignment,
  mapButtonSizeStyle,
} from '../../common';
import { colorPrimary, toCamelCase } from '~/common/utils';
import {
  Button,
  ButtonProps,
  ButtonTypeEnum,
  IconEnum,
  SvgIcon,
} from '../../../../common/components/ui-elements';
import { Property } from 'csstype';

const HeroCardCtas = memo(
  ({ contentCtas, visible, alignment, isStackable, parentId }: ICTAs) => {
    const animateStyle = useSpring(mediaAnimationProps(visible));
    const _contentCTAs = contentCtas?.filter((x) => !x.ctaHidden);

    return (
      <div className={styles.HeroCardCtas}>
        {_contentCTAs && _contentCTAs.length > 0 && (
          <animated.div
            style={{
              ...animateStyle,
              ...(isStackable
                ? {
                    display: 'flex',
                    flexDirection: 'column',
                    ...mapCTAsAlignment(alignment || ''),
                  }
                : alignment
                ? {
                    textAlign: alignment as Property.TextAlign,
                  }
                : {}),
            }}
          >
            {_contentCTAs?.map((cta, idx) => {
              const _style: CSSProperties = {
                ...(cta.ctaColor ? { color: `#${cta.ctaColor}` } : {}),
                ...(cta.ctaColor ? { borderColor: `#${cta.ctaColor}` } : {}),
                ...mapButtonSizeStyle(cta.ctaButtonSize || ''),
              };
              const _target = cta.ctaUrl?.target || '_self';
              const buttonProps: ButtonProps = {
                type: toCamelCase(
                  cta.ctaButtonType
                ) as keyof typeof ButtonTypeEnum,
                href: cta.ctaUrl?.url,
                target: _target,
                style: _style,
                buttonWidth: cta.ctaFullWidth,
                buttonSize:
                  (cta.ctaButtonSize?.toLowerCase() as ButtonProps['buttonSize']) ||
                  'medium',
              };
              if (
                cta.ctaUrl?.url &&
                cta.ctaUrl?.url.includes(window.location.pathname) &&
                _target === '_self'
              ) {
                buttonProps.onClick = () => {
                  window.localStorage.setItem('prevSectionId', `${parentId}`);
                };
              }

              const renderCTA = () => (
                <Fragment key={idx}>
                  <Button
                    className={cn(
                      styles.HeroCardCta,
                      styles[`HeroCardCta${buttonProps.type}`],
                      {
                        [styles.HeroCardCtaColor]: !!cta.ctaColor,
                      }
                    )}
                    {...buttonProps}
                  >
                    {cta.ctaIcon && cta.ctaIcon !== 'none' && (
                      <SvgIcon
                        type={cta.ctaIcon as keyof typeof IconEnum}
                        color={`#${colorPrimary}`}
                        size={1.2}
                        strokeWidth={2}
                        style={{
                          padding: '0px 5px 1.5px 0px',
                          alignSelf: 'start',
                          lineHeight: 'unset',
                        }}
                      />
                    )}
                    <span
                      className={styles.HeroCardCtaText}
                      style={
                        buttonProps.type === 'text'
                          ? { color: `#${colorPrimary}` }
                          : {}
                      }
                    >
                      {cta.ctaUrl?.name || ''}
                    </span>
                  </Button>
                </Fragment>
              );

              return (
                <Fragment key={idx}>
                  {isStackable ? (
                    <div
                      className={styles.HeroCardCtaContainer}
                      style={{
                        ...(cta.ctaButtonAlignment || alignment
                          ? {
                              textAlign:
                                cta.ctaButtonAlignment ||
                                (alignment as Property.TextAlign),
                            }
                          : {}),
                      }}
                    >
                      {renderCTA()}
                    </div>
                  ) : (
                    renderCTA()
                  )}
                </Fragment>
              );
            })}
          </animated.div>
        )}
      </div>
    );
  }
);

export default HeroCardCtas;
